import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
const service = "BOOKKEEPING";
import { helperFunctions } from "@/composable/helperFunctions";
const toast = useToast();
import { useToast } from "vue-toastification";

const { downloadBase64 } = helperFunctions;

export const SEND_INVOICE = async (payload,receipt) => {
  const { downloadInvoiceId, email } = payload;
  try {
    let res = await query({
      endpoint: receipt ?'DownloadReceipt':"DownloadInvoice",
      payload: {
        downloadInvoiceId,
        downloadReceiptId:downloadInvoiceId,
        email,
      },
      service,
    });
    return res;
  } catch (e) {
    toast.error(e.message);
  }
};
export const GETANINVOICEDETAILED = async (id,companyId) => {
  // getBookkeepingClients
  const payload = {
    "viewBookKeepingInvoicesByClientId": id,
    "companyId": companyId
  };
  try {
    const res = await query({
      endpoint: "ViewBookKeepingInvoicesByClient",
      service,
      payload,
    });

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);

      return null;
    }
  } catch (error) {
    throw error;
  }
};
export const GETANINVOICE = async (id) => {
  // getBookkeepingClients
  const payload = {
    viewBookKeepingInvoicesId: id,
  };
  try {
    const res = await query({
      endpoint: "ViewBookKeepingInvoices",
      service,
      payload,
    });

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);

      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const GETINVOICES = async (
  pagination,
  archived = null,
  status = null
) => {
  // getBookkeepingClients
  const payload = {
    paging: {
      limit: 10,
      page: 1,
      search: null,
      sort: null,
      filter: null,
      ...pagination,
    },
    status,
    archived,
  };
  try {
    const res = await query({
      endpoint: "GetAllBookKeepingInvoices",
      service,
      payload,
    });

    // if (res.success) {
    console.log("data here", res.data);
    store.$patch({
      bookkeepingInvoices: res.data,
    });
    // }

    console.log(res);
  } catch (error) {
    throw error;
  }
};

<template>
  <div>
    <easiPopover
      :options="filterOptions"
      :data="filterOptions"
      :modify="{ top: 50, left: 80 }"
      class="max-h-52"
    >
      <easiButton
        rounded="2xl"
        variant="outlined"
        color="gray"
        class="bg-white text-dark-600 gap-2"
      >
        <img class="" src="@/assets/icons/filter.svg" alt="" />
        Filter {{ activeFilter && "-" }}
        {{ activeFilter ? activeFilter.title : "" }}
      </easiButton>

      <template #items="slotsProp">
        <div class="flex items-center gap-2">
          <img
            v-show="activeFilter && activeFilter.title === slotsProp.item.title"
            src="@/assets/icons/checked-square.svg"
            alt=""
          />
          <img
            v-show="activeFilter && activeFilter.title !== slotsProp.item.title"
            src="@/assets/icons/unchecked-box.svg"
            alt=""
          />
          <span> {{ slotsProp.item.title }}</span>
        </div>
      </template>
    </easiPopover>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from "vue";
import { GETINVOICES } from "@/services/book-keeping/invoice";

const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const activeFilter = ref(null);
const filterOptions = [
  {
    title: "All",
    value: "",
    action: (val) => {
      console.log(val);

      activeFilter.value = val[0];
    },
  },
  {
    title: "A - Z",
    value: "ASC",
    action: (val) => {
      activeFilter.value = val[1];
    },
  },
  {
    title: "Z - A",
    value: "DESC",
    action: (val) => {
      activeFilter.value = val[2];
    },
  },
  {
    title: "Paid",
    value: "paid",
    action: (val) => {
      activeFilter.value = val[3];
    },
  },
  {
    title: "Pending",
    value: "pending",
    action: (val) => {
      activeFilter.value = val[4];
    },
  },
];

watch(activeFilter, () => {
  if (activeFilter.value) {
    handleFilter();
  }
});

const handleFilter = async () => {
  try {
    emit("update:loading", true);
    const payload = {
      sort: {
        name: ["paid", "pending"].includes(activeFilter.value.value)
          ? null
          : activeFilter.value.value,
      },
    };
    await GETINVOICES(
      payload,
      null,
      ["paid", "pending"].includes(activeFilter.value.value)
        ? activeFilter.value.value
        : null
    );
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
};
</script>

<style></style>

<template>
  <div class="flex min-h-screen flex-col gap-2 sm:gap-4">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-xl">All Items</h3>
      <easiPaginAsync
        v-if="visibleData.length"
        infoOnly
        leftAlign
        :totalRecords="totalRecords"
        v-model:rowsPerPage="paginationPayload.limit"
        v-model:page="paginationPayload.page"
      />
    </div>

    <easiPlainTable
      v-if="visibleData.length"
      :data="visibleData"
      :headers="tableHeaders"
      :loading="loading"

      v-model:selected="selectedData"
    >
      <template #name="slotsProp">
        <div class="flex items-center justify-start">
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.firstName
          }}
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.lastName
          }}
        </div>
      </template>
      <template #email="slotsProp">
        <div class="flex items-center justify-start">
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.email
          }}
        </div>
      </template>
      <template #action="slotsProp">
        <div class="flex items-center justify-center">
          <easiPopover :data="slotsProp.data" :options="dropdownOptions">
            <i class="pi pi-ellipsis-v text-dark-600" />
          </easiPopover>
        </div>
      </template>
      <template #status="slotProps">
        <div
          :class="[
            getStatusColor(slotProps.data.status),
            'px-4 py-1.5 rounded-full capitalize text-center',
          ]"
        >
          {{ slotProps.data.status && slotProps.data.status.toLowerCase() }}
        </div>
      </template>
    </easiPlainTable>
    <div v-else class="text-center">No Invoice to show</div>
    <easiPaginAsync
      v-if="visibleData.length"
      :totalRecords="totalRecords"
      v-model:rowsPerPage="paginationPayload.limit"
      v-model:page="paginationPayload.page"
    />
  </div>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>

  <!-- //////  Alert Modal ////// -->

  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
  <easiAlert
    v-if="sendModal"
    title="Send Invoice"
    caption=""
    @close="sendModal = false"
    noIcon
  >
    <div class="flex flex-col w-11/12 gap-6 mx-auto">
      <easiTextInput
        label="email"
        placeholder="Client's email address"
        name="email"
        type="email"
        class=""
        v-model="clientEmail"
        :error="errorRules.clientEmail"
      />
      <div class="flex flex-col gap-2">
        <p class="text-xs text-grey">Copy link</p>
        <div
          class="rounded-xl bg-newAsh border-outlineGray px-4 py-3 flex justify-between items-center"
        >
          <p class="break-all mr-2">{{ url }}</p>
          <div
            @click="copyText()"
            class="flex gap-3 cursor-pointer bg-white rounded-full px-6 py-3"
          >
            <img src="@/assets/icons/copy-new.svg" alt="" />

            <p class="text-xs text-primary-deep">Copy</p>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <easiButton size="medium" @click="sendInvoice" block
        :loading="loading"

          >Send Invoice</easiButton
        >
      </div>
    </div>
  </easiAlert>
  <easiAlert
    v-if="showSentInvoice"
    title="Save Invoice"
    caption="Invoice sent successfully!"
  >
    <div class="w-11/12 mx-auto">
      <easiButton size="medium" @click="showSentInvoice = false" block
        >Dismiss</easiButton
      >
    </div>
  </easiAlert>
  <!-- <easiLoading v-if="loading" @close="loading = false"> </easiLoading> -->
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";

import { ref, computed, reactive, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";
import { SEND_INVOICE } from "@/services/book-keeping/invoice";
import { useToast } from "vue-toastification";
import { GETINVOICES } from "@/services/book-keeping/invoice";

const toast = useToast();

const { formatAmount, formatDate, downloadBase64 } = helperFunctions;
const store = useDataStore();
const router = useRouter();
const clientEmail = ref("");
const errorRules = reactive({
  clientEmail: false,
});
const alertMessage = ref("");
const isAlert = ref(false);
const sendModal = ref(false);
const loading = ref(false)
const selectedInvoice = ref({});

const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);
const showSentInvoice = ref(false);

const selectedData = ref([]);
const hostUrl = window.location.host;
const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const url = computed(() => {
  return `${hostUrl}/payInvoice?id=${selectedInvoice.value._id}&companyId=${selectedInvoice.value.companyId}`;
});
function copyText() {
  navigator.clipboard.writeText(url.value);
  toast.info("Copied!");
}
const storeBookKeepingInvoices = computed(
  () => store.getBookkeepingInvoices.docs || []
);
const totalRecords = computed(() =>
  store.getBookkeepingInvoices && store.getBookkeepingInvoices.paginationData
    ? store.getBookkeepingInvoices.paginationData.totalRecords
    : 0
);
const paginationPayload = reactive({
  limit: 20,
  page: 1,
});

watch(paginationPayload, () => {
  queryInvoice();
});
const queryInvoice = async () => {
  try {
    emit("update:loading", true);
    await GETINVOICES(paginationPayload);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
};
const sendInvoice = async (download) => {
  if (!clientEmail.value && download !== true)
    return (errorRules.clientEmail = "Please add an email address");
  try {
    loading.value = true;

    const payload = {
      downloadInvoiceId: selectedInvoice.value._id,
      email: clientEmail.value,
    };
    if (download === true) {
      payload.email = "";
    }
    const res = await SEND_INVOICE(payload);
    if (res.success === true) {
      if (download === true) {
        const base64 = res.data.base64;
        const fileName = res.data.fileName;
        downloadBase64(base64, fileName);
      } else {
        sendModal.value = false;
        showSentInvoice.value = true;
      }
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};
const visibleData = computed(() => {
  return storeBookKeepingInvoices.value || [];
});

const tableHeaders = [
  {
    style: ``,
    title: "NAME OF CUSTOMER",
    field: "name",
    type: "name",
  },
  {
    style: ``,
    title: "EMAIL ADDRESS",
    field: "email",
    type: "email",
  },
  {
    style: ``,
    title: "INVOICE NUMBER",
    field: "invoiceNo",
  },
  {
    style: ``,
    title: "Date Created",
    field: "createdAt",
    type: "date",
    format: (val) => {
      return val ? formatDate(val, "DD/MM/YYYY") : "--";
    },
  },
  {
    style: ``,
    title: "STATUS",
    field: "status",
    type: "status",
  },
  {
    title: "ACTION",
    field: "",
    type: "action",
  },
];
function getStatusColor(status) {
  const obj = {
    unPaid: `text-error bg-error-background`,
    paid: `text-success bg-statusGreen`,
  };

  return obj[status] || "";
}
const dropdownOptions = [
  {
    title: "View",
    action: (val) => {
      router.push({
        name: "ViewInvoice",
        params: { id: val._id },
        query: {
          companyId: val.companyId,
        },
      });
    },
  },
  {
    title: "Send",
    action: (val) => {
      console.log("val is", val);
      selectedInvoice.value = val;

      sendModal.value = true;
    },
  },
  {
    title: "Download",
    action: (val) => {
      selectedInvoice.value = val;
      sendInvoice(true);
    },
  },
];
const showAlert = () => {
  alert("Clicked");
};

const handleDelete = () => {
  deleteLoading.value = true;

  setTimeout(() => {
    deleteLoading.value = false;
    deleteModal.value = false;

    alertMessage.value = "Item successfully deleted";
    isAlert.value = true;
  }, 3000);
};
</script>

<style></style>

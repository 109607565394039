<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-6">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Invoices
    </h4>

    <!-- //// Search and Filter ///// -->

    <div class="flex flex-col md:flex-row items-center justify-between gap-4">
      <div class="flex-grow w-full">
        <easiSearch
          placeholder="Search for name, invoice no, date.. "
          v-model="searchInput"
          @search="onSearch"
          :suggestion="false"
        />
      </div>

      <div class="flex items-center gap-4">
        <InvoiceFilter v-model:loading="isLoading" />

        <easiButton @click="$router.push({ name: 'AddInvoice' })" class="gap-2">
          <i class="pi pi-plus text-sm" /> Create Invoice</easiButton
        >
      </div>
    </div>

    <!-- //// Table ///// -->
    <ListInvoice v-model:loading="isLoading" />
    <easiLoading v-if="loadingModal" />
  </main>
</template>

<script setup>
import InvoiceFilter from "@/components/BookKeeping/Invoice/InvoiceFilter.vue";
import ListInvoice from "@/components/BookKeeping/Invoice/ListInvoice";
import { computed, ref, onMounted, watch } from "vue";
import { GETINVOICES } from "@/services/book-keeping/invoice";
import { useDataStore } from "@/stores/data";
import { debouncedValue } from "@/composable/debouncedValue";

const store = useDataStore();
const storeBookKeepingInvoices = computed(
  () => store.getBookkeepingInvoices.docs || []
);
const searchInput = ref("");
const debounceSearch = debouncedValue(searchInput);
const isLoading = ref(false);
watch(debounceSearch, () => {
  onSearch();
});
const onSearch = async () => {
  try {
    searchInput.value ? (isLoading.value = true) : (loadingModal.value = true);
    await GETINVOICES({ search: searchInput.value });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
    loadingModal.value = false;
  }
};
const loadingModal = ref(false);
onMounted(async () => {
  try {
    loadingModal.value = !storeBookKeepingInvoices.value.length;
    await GETINVOICES();
  } catch (error) {
    console.log(error);
  } finally {
    loadingModal.value = false;
  }
});
</script>

<style></style>
